import {Observable} from '@PosterWhiteboard/observable';
import {isObjectVisible} from '@Utils/fabric.util';
import {ElementDataType} from '@Libraries/add-media-library';
import {uploadFile, UserMediaType} from '@Libraries/user-media-library';
import {hideLoading, isLoaderActiveForKey, showLoading} from '@Libraries/loading-toast-library';
import type {DrawObject} from '@PosterWhiteboard/models/draw.class';
import {CIRCLE_BRUSH, DEFAULT_DRAW_COLOR, Draw, ERASER_BRUSH, PENCIL_BRUSH, SPRAY_BRUSH} from '@PosterWhiteboard/models/draw.class';
import type {RGBA} from '@Utils/color.util';
import {RGBAToRgbaString} from '@Utils/color.util';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {getUserId} from '@Libraries/user.library';
import {openErrorModal} from '@Modals/error-modal';
import {ImageItemSource} from '@Libraries/image-item.library';
import type {CanvasEvents, FabricObject} from '@postermywall/fabricjs-2';
import {Group, PencilBrush, Canvas, CircleBrush, SprayBrush} from '@postermywall/fabricjs-2';
import type {ErasingEvent} from '@ThirdParty/eraser-brush/EraserBrush';
import {EraserBrush} from '@ThirdParty/eraser-brush/EraserBrush';
import {getScaleToFit} from '@Utils/math.util';
import {v4 as uuidv4} from 'uuid';
import type {DeepPartial} from '@/global';

const DRAWING_LOADING_KEY = 'loadingDrawing';
const HIGH_RES_DRAWING_MAX_DIMENSION = 6000;
const DRAWING_IMAGE_EXT = 'webp';

interface DrawingEvent {
  path: FabricObject;
  targets?: Array<FabricObject>;
}

export interface DrawingObject {
  isDrawModeOn: boolean;
  isImageBeingGenerated: boolean;
  drawBrush: DrawObject;
}

export class PosterDrawing extends Observable {
  public poster: Poster;
  public isDrawModeOn = false;
  public isImageBeingGenerated = false;
  public drawBrush: Draw;
  public lastDrawBrushColor: RGBA = DEFAULT_DRAW_COLOR;
  public drawnFabricObjects: Array<FabricObject> = [];

  public constructor(poster: Poster) {
    super();
    this.poster = poster;
    this.drawBrush = new Draw();
  }

  public toObject(): DrawingObject {
    return {
      isDrawModeOn: this.isDrawModeOn,
      isImageBeingGenerated: this.isImageBeingGenerated,
      drawBrush: this.drawBrush.toObject(),
    };
  }

  private async setDrawMode(modeState: boolean): Promise<void> {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    this.isDrawModeOn = modeState;
    const currentPage = this.poster.getCurrentPage();
    canvas.isDrawingMode = this.isDrawModeOn;
    if (this.isDrawModeOn) {
      this.drawBrush.strokeColor = [...this.lastDrawBrushColor];
      currentPage.activeSelection?.clearSelection();
      this.initDrawBrush(this.drawBrush);
      await this.poster.history.addDrawingHistory();

      trackPosterBuilderGA4Events(GA4EventName.START_DRAWING);
    } else {
      this.toggleGenerationState(true);
      this.toggleDrawingLoadingStates();
      await this.exitPencilDrawingMode();
      this.hideDrawingLoader();
      this.toggleGenerationState(false);
    }
  }

  toggleGenerationState = (state: boolean): void => {
    this.updateFromObject({
      isImageBeingGenerated: state,
    });
  };

  public isInEraseMode(): boolean {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return false;
    }

    return canvas.isDrawingMode && canvas.freeDrawingBrush instanceof EraserBrush;
  }

  public onPathDrawn(e: CanvasEvents['path:created']): void {
    this.addPath(e);
    e.path.shadow = null;
    e.path.set({
      erasable: true,
    });

    this.drawnFabricObjects.push(e.path);
    void this.poster.history.addDrawingHistory();
  }

  public async getDrawnFabricObjects(): Promise<FabricObject[]> {
    const promises = [];
    for (const drawnFabricObject of this.drawnFabricObjects) {
      promises.push(drawnFabricObject.clone());
    }

    return Promise.all(promises);
  }

  public updateCurrentDrawingFromFabricObjects(fabricObjects: FabricObject[]): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    for (const drawnFabricObject of this.drawnFabricObjects) {
      canvas.remove(drawnFabricObject);
    }

    canvas.add(...fabricObjects);
    this.drawnFabricObjects = fabricObjects;
  }

  public addPath(e: DrawingEvent): void {
    if (e.path && !this.isInEraseMode()) {
      e.path.shadow = null;
      e.path.set({
        erasable: true,
      });

      e.path.set('selectable', false);
    }
  }

  public async onErasingEnd(e: ErasingEvent<'end'>): Promise<void> {
    e.preventDefault();
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas) || !(canvas.freeDrawingBrush instanceof EraserBrush)) {
      return;
    }

    await canvas.freeDrawingBrush.commit(e.detail);
    await this.poster.history.addDrawingHistory();
    canvas.requestRenderAll();
  }

  public async exitPencilDrawingMode(): Promise<void> {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    const currentPage = this.poster.getCurrentPage();
    canvas.isDrawingMode = false;
    this.poster.history.removeDrawingHistoryStates();
    const drawingGroup = this.getGroupForDrawnObjects();
    const isDrawingVisible = drawingGroup ? await isObjectVisible(drawingGroup) : false;

    if (!isDrawingVisible || !drawingGroup) {
      return;
    }

    let imageURL: string;
    try {
      const tempUploadingImageUID = uuidv4();
      imageURL = (await this.uploadDrawing(drawingGroup, tempUploadingImageUID)) as string;
      await currentPage.items.addItems.addImageItem(
        {
          x: drawingGroup.left,
          y: drawingGroup.top,
          scaledWidth: drawingGroup.width,
          scaledHeight: drawingGroup.height,
          scaleX: 1,
          scaleY: 1,
          extension: DRAWING_IMAGE_EXT,
          source: ImageItemSource.DRAWING,
          type: ElementDataType.IMAGE,
          hasTransparency: true,
          uploaderId: getUserId() ?? 0,
          dataUrl: imageURL,
          uid: tempUploadingImageUID,
        },
        {
          effects: {
            tintOpacity: 1.0,
          },
        }
      );
    } catch (e) {
      openErrorModal({
        message: window.i18next.t('pmwjs_drawing_upload_error'),
      });
      console.error(e);
    } finally {
      this.clearDrawnFabricObjects();
    }
  }

  public clearDrawnFabricObjects(): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;

    for (const drawnFabricObject of this.drawnFabricObjects) {
      canvas.remove(drawnFabricObject);
    }

    this.drawnFabricObjects = [];
  }

  public async uploadDrawing(drawingGroup: Group, tempUploadingImageUID?: string): Promise<any> {
    const storageDrawingImage = drawingGroup.toDataURL({
      format: 'png',
      quality: 1,
      multiplier: getScaleToFit(HIGH_RES_DRAWING_MAX_DIMENSION, HIGH_RES_DRAWING_MAX_DIMENSION, drawingGroup.getScaledWidth(), drawingGroup.getScaledHeight()),
    });
    const source = ImageItemSource.DRAWING;
    const UPLOAD_LOADING_KEY = 'uploadingUserMedia';

    const fd = new FormData();
    fd.append('Filedata', storageDrawingImage);
    fd.append('type', UserMediaType.DRAWING);
    if (tempUploadingImageUID) {
      fd.append('tempUploadingImageUID', tempUploadingImageUID);
    }

    void uploadFile(fd, UPLOAD_LOADING_KEY, UserMediaType.DRAWING, source);

    return storageDrawingImage;
  }

  public getGroupForDrawnObjects(): Group | undefined {
    return this.drawnFabricObjects.length > 0 ? new Group(this.drawnFabricObjects) : undefined;
  }

  public toggleDrawingLoadingStates(): void {
    const isDrawingLoaderActive = isLoaderActiveForKey(DRAWING_LOADING_KEY);
    if (isDrawingLoaderActive) {
      this.hideDrawingLoader();
    } else {
      this.showDrawingLoader();
    }
  }

  public hideDrawingLoader(): void {
    hideLoading(DRAWING_LOADING_KEY);
  }

  public showDrawingLoader(): void {
    showLoading(DRAWING_LOADING_KEY, {
      text: window.i18next.t('pmwjs_finishing_drawing'),
      hideIcon: true,
    });
  }

  public initDrawBrush(drawVO: Draw): void {
    this.changeLineBrush(drawVO.brush);
    this.changeLineColor(RGBAToRgbaString(drawVO.strokeColor));
    this.changeLineWidth(drawVO.strokeWeight);
  }

  public changeLineBrush(brushType: string): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    if (brushType === PENCIL_BRUSH) {
      canvas.freeDrawingBrush = new PencilBrush(canvas);
    } else if (brushType === CIRCLE_BRUSH) {
      canvas.freeDrawingBrush = new CircleBrush(canvas);
    } else if (brushType === SPRAY_BRUSH) {
      canvas.freeDrawingBrush = new SprayBrush(canvas);
    } else if (brushType === ERASER_BRUSH) {
      const eraserBrush = new EraserBrush(canvas);
      eraserBrush.on('end', this.onErasingEnd.bind(this));
      canvas.freeDrawingBrush = eraserBrush;
    } else {
      throw new Error('unsupported brush selected');
    }
    this.changeLineBrushCursor(brushType);
  }

  public changeLineBrushCursor(brushType: string): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    const brushCursorPlacement = '10 25 , auto';
    const brushTypes = [PENCIL_BRUSH, CIRCLE_BRUSH, SPRAY_BRUSH, ERASER_BRUSH];
    if (!brushTypes.includes(brushType)) {
      throw new Error('Invalid brush type passed');
    }
    const brushCursorSVGPath = `url("${window.PMW.util.asset_url(`images/postermaker/drawing-mode-cursors/${brushType}.svg`)}")`;
    canvas.freeDrawingCursor = `${brushCursorSVGPath} ${brushCursorPlacement}`;
  }

  public changeLineColor(color: string): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    const {freeDrawingBrush} = canvas;
    if (freeDrawingBrush) {
      freeDrawingBrush.color = color;
    }
  }

  public changeLineWidth(width: number): void {
    const canvas = this.poster.getCurrentPage().fabricCanvas;
    if (!(canvas instanceof Canvas)) {
      return;
    }

    const {freeDrawingBrush} = canvas;
    if (freeDrawingBrush) {
      freeDrawingBrush.width = parseInt(width.toString(), 10);
    }
  }

  public onDrawBrushSelected(brushType: string): void {
    if (this.drawBrush) {
      this.drawBrush.brush = brushType;
      this.changeLineBrush(this.drawBrush.brush);

      if (brushType === ERASER_BRUSH) {
        this.changeLineWidth(this.drawBrush.strokeWeight);
      } else {
        this.changeLineWidth(this.drawBrush.strokeWeight);
        this.changeLineColor(RGBAToRgbaString(this.drawBrush.strokeColor));
      }
    }
  }

  public invalidate(drawObject: DeepPartial<DrawingObject>): void {
    if ('isDrawModeOn' in drawObject) {
      void this.setDrawMode(this.isDrawModeOn);
      this.onDrawBrushSelected(PENCIL_BRUSH);
    }
    if (this.drawBrush?.brush) {
      this.onDrawBrushSelected(this.drawBrush?.brush);
    }
    if (this.drawBrush?.strokeWeight) {
      this.changeLineWidth(this.drawBrush.strokeWeight);
    }
    if (this.drawBrush.strokeColor) {
      this.changeLineColor(RGBAToRgbaString(this.drawBrush.strokeColor));
    }
  }

  public copyVals(obj: DeepPartial<DrawingObject> = {}): void {
    const {drawBrush, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.drawBrush.copyVals(drawBrush);
  }

  public updateFromObject(drawObject: DeepPartial<DrawingObject>): void {
    this.copyVals(drawObject);
    this.invalidate(drawObject);
    this.poster.redux.updateReduxData();
  }

  public toggleDrawMode(): void {
    this.updateFromObject({
      isDrawModeOn: !this.isDrawModeOn,
    });
  }

  public getDefaultStrokeWeightForBrush(id: string): number {
    switch (id) {
      case ERASER_BRUSH:
        return 25;
      case SPRAY_BRUSH:
        return 20;
      case PENCIL_BRUSH:
      case CIRCLE_BRUSH:
      default:
        return 10;
    }
  }

  public updateBrushType(brushName: string): void {
    this.updateFromObject({
      drawBrush: {
        brush: brushName,
        strokeWeight: this.getDefaultStrokeWeightForBrush(brushName),
      },
    });
  }

  public updateBrushSize(size: number): void {
    this.updateFromObject({
      drawBrush: {
        strokeWeight: size,
      },
    });
  }

  public updateDrawModeColor(color: RGBA): void {
    this.updateFromObject({
      drawBrush: {
        strokeColor: color,
      },
    });
  }

  public disableDrawMode(): void {
    this.updateFromObject({
      isDrawModeOn: false,
    });
  }
}
