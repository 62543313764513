import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {RESELECT_ITEM_EVENT_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {v4 as uuidv4} from 'uuid';
import {calculatePageDuration} from '@PosterWhiteboard/libraries/video-page';
import {PageItems} from '@PosterWhiteboard/page/page-items.class';
import {PageBackground} from '@PosterWhiteboard/page/page-background.class';
import {Observable} from '@PosterWhiteboard/observable';
import {PageAnimation} from '@PosterWhiteboard/page/page-animation.class';
import type {PageObject} from '@PosterWhiteboard/page/page.types';
import {ItemBordersOnHovering} from '@PosterWhiteboard/page/item-borders-on-hovering.class';
import {initTransformDetail} from '@PosterWhiteboard/page/item-transform-details';
import {RenderBleed} from '@PosterWhiteboard/page/render-bleed.class';
import {RenderGrid} from '@PosterWhiteboard/page/render-grid.class';
import {RenderAlignmentGuidelines} from '@PosterWhiteboard/page/render-alignment-guidelines.class';
import {RenderFold} from '@PosterWhiteboard/page/render-fold.class';
import {PageSpellCheck} from '@PosterWhiteboard/page/page-spellcheck.class';
import {updatePopUpStateAndPosition} from '@Components/poster-editor/components/poster-popup-menu/poster-popup-menu-slice';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {SelectionManager} from '@PosterWhiteboard/page/active-selection.class';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import {PageClipboard} from '@PosterWhiteboard/page/page-clipboard';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';
import {PageSnapshot} from '@PosterWhiteboard/page/page-snapshot.class';
import {PageSVG} from '@PosterWhiteboard/page/page-svg.class';
import {PageWatermark} from '@PosterWhiteboard/page/page-watermark.class';
import {handleTextSelectionPopupMenu, updateTextSelection} from '@Libraries/text-selection-library';
import {isMobile} from 'react-device-detect';
import {updateSidebarState} from '@Components/poster-editor/poster-editor-reducer';
import {openPosterEditorMoreOptionsModal} from '@Modals/poster-editor-more-options-modal/poster-editor-more-options-modal';
import {CanvasPanZoom} from '@PosterWhiteboard/page/page-canvas-pan-zoom.class';
import {CanvasPanOnTextEdit} from '@PosterWhiteboard/page/page-canvas-pan-on-text-edit.class';
import {getFabricObjectUID, type ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import {redirectUser} from '@Utils/browser.util';
import type {CanvasEvents, FabricImage, FabricObject, Group, TFiller} from '@postermywall/fabricjs-2';
import {ActiveSelection, util, Textbox, StaticCanvas, Canvas} from '@postermywall/fabricjs-2';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import Emitter, {EVENTS} from '@/services/event';
import type {DeepPartial} from '@/global';
import type {CornerPoints} from '@/utils/math.util';
import 'hammerjs';

export interface AddItemOpts {
  undoable?: boolean;
  updateRedux?: boolean;
  zIndex?: number;
  checkForDurationUpdate?: boolean;
}

export class Page extends Observable {
  public hashedID = '';
  public fabricCanvas!: Canvas | StaticCanvas;
  public fabricCanvasHammerManager?: HammerManager;
  public hammerEventFunctions;
  public background!: PageBackground;
  public items!: PageItems;
  public introAnimation!: PageAnimation;
  public poster: Poster;
  public duration = 0;
  public itemBordersOnHovering: ItemBordersOnHovering;
  public renderAlignmentGuidelines: RenderAlignmentGuidelines;
  public renderBleed: RenderBleed;
  public renderGrid: RenderGrid;
  public renderFold: RenderFold;
  public pageWatermark: PageWatermark;
  public pageSnapshot: PageSnapshot;
  public pageSVG: PageSVG;
  public spellCheck: PageSpellCheck;
  public clipboard: PageClipboard;
  public activeSelection: SelectionManager;
  public canvasPanZoom: CanvasPanZoom;
  public canvasPanOnTextEdit: CanvasPanOnTextEdit;
  public longPressInitiated: boolean;

  public constructor(poster: Poster, hashedID?: string) {
    super();
    this.poster = poster;
    this.hashedID = hashedID ?? uuidv4();
    this.background = new PageBackground(this);
    this.introAnimation = new PageAnimation(this);
    this.items = new PageItems(this);
    this.activeSelection = new SelectionManager(this);
    this.canvasPanZoom = new CanvasPanZoom(this);
    this.canvasPanOnTextEdit = new CanvasPanOnTextEdit(this);
    this.longPressInitiated = false;
    this.hammerEventFunctions = {
      longpress: this.openTouchContextualMenu.bind(this),
      singletap: this.onSingleTapOnCanvas.bind(this),
      doubletap: this.onCanvasDoubleTap.bind(this),
      singleFingerPan: this.canvasPanZoom.onCanvasSingleFingerPan.bind(this.canvasPanZoom),
      twoFingerPan: this.canvasPanZoom.onCanvasTouchGesture.bind(this.canvasPanZoom),
      pinch: this.canvasPanZoom.onCanvasTouchGesture.bind(this.canvasPanZoom),
    };

    this.addCanvasToPoster();
    this.itemBordersOnHovering = new ItemBordersOnHovering(this);
    this.renderAlignmentGuidelines = new RenderAlignmentGuidelines(this);
    this.renderBleed = new RenderBleed(this);
    this.renderGrid = new RenderGrid(this);
    this.renderFold = new RenderFold(this);
    this.pageWatermark = new PageWatermark(this);
    this.pageSnapshot = new PageSnapshot(this);
    this.pageSVG = new PageSVG(this);
    if (this.fabricCanvas instanceof Canvas) {
      initTransformDetail(this.fabricCanvas);
    }
    this.spellCheck = new PageSpellCheck(this);
    this.clipboard = new PageClipboard(this);
    Emitter.on(EVENTS.USER_LOGGED_IN, this.onUserLoggedIn.bind(this));
  }

  public applyScale(): void {
    if (this.poster.hasFakeScrollbars()) {
      const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');
      if (!whiteboardContainer) {
        return;
      }

      const newCanvasTotalWdith = this.poster.width * this.poster.scaling.scale;
      const newCanvasTotalHeight = this.poster.height * this.poster.scaling.scale;

      const newCanvasVisibleWidth = newCanvasTotalWdith < whiteboardContainer.clientWidth ? newCanvasTotalWdith : whiteboardContainer.clientWidth;
      const newCanvasVisibleHeigth = newCanvasTotalHeight < whiteboardContainer.clientHeight ? newCanvasTotalHeight : whiteboardContainer.clientHeight;

      this.fabricCanvas.setDimensions({
        width: newCanvasVisibleWidth,
        height: newCanvasVisibleHeigth,
      });
    } else {
      this.fabricCanvas.setDimensions({
        width: this.poster.width * this.poster.scaling.scale,
        height: this.poster.height * this.poster.scaling.scale,
      });
    }

    this.fabricCanvas.setZoom(this.poster.scaling.scale);
    this.fabricCanvas.requestRenderAll();
  }

  public async addItem(newItem: ItemType, {updateRedux = true, undoable = true, zIndex, checkForDurationUpdate = false}: AddItemOpts = {}): Promise<void> {
    newItem.page = this;
    this.items.addItem(newItem);
    await newItem.onItemAddedToPage();
    if (zIndex !== undefined) {
      this.fabricCanvas.insertAt(zIndex, newItem.fabricObject);
    } else {
      this.fabricCanvas.add(newItem.fabricObject);
    }
    if (!this.poster.mode.isGeneration()) {
      this.fabricCanvas.requestRenderAll();
    }
    if (checkForDurationUpdate) {
      this.checkForPageDurationUpdate(newItem);
    }

    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
  }

  public isMemoryIntensive(): boolean {
    const amountOfHeavyItems = this.items.getImageItems().length + this.items.getSlideshowItems().length + this.items.getVideoItems().length;
    if (this.isVideo()) {
      return amountOfHeavyItems > 5;
    }
    return amountOfHeavyItems > 30;
  }

  private onUserLoggedIn(): void {
    this.pageWatermark.refreshWatermark();
  }

  private checkForPageDurationUpdate(item: ItemType): void {
    if (item.isVideo() || item.isSticker() || item.isTranscript() || (item.isSlideshow() && item.isStreamingItem())) {
      this.calculateAndUpdatePageDuration();
    }
  }

  public getNonScaledCanvasScaleddWidth(): number {
    return this.fabricCanvas.width / this.fabricCanvas.getZoom();
  }

  public getNonScaledCanvasScaleddHeight(): number {
    return this.fabricCanvas.height / this.fabricCanvas.getZoom();
  }

  public calculateAndUpdatePageDuration({updateRedux = false, undoable = false, ...opts}: UpdateFromObjectOpts = {}): void {
    const updatedDuration = calculatePageDuration(this);
    if (updatedDuration < this.duration && this.poster.getCurrentTime() > updatedDuration) {
      void this.poster.stop();
    }

    void this.updateFromObject(
      {
        duration: updatedDuration,
      },
      {updateRedux, undoable, ...opts}
    );
  }

  public isExpensiveVideo(): boolean {
    return this.items.hasVideoItem() || this.items.hasVideoContainingSlideshowItem();
  }

  public getItemAtTop(): ItemType | undefined {
    const objs = this.fabricCanvas.getObjects();

    for (let i = objs.length - 1; i >= 0; i--) {
      if (objs[i].__PMWID) {
        return this.items.getItem(objs[i].__PMWID);
      }
    }
    return undefined;
  }

  public getPageOrder(): number | undefined {
    return this.poster.pages.getPageOrder(this.hashedID);
  }

  public getItemAtBottom(): ItemType | undefined {
    const objs = this.fabricCanvas.getObjects();

    for (let i = 0; i < objs.length; i++) {
      if (objs[i].__PMWID) {
        return this.items.getItem(objs[i].__PMWID);
      }
    }
    return undefined;
  }

  public getDuration(): number {
    return this.duration;
  }

  private onMouseDown(e: CanvasEvents['mouse:down']): void {
    this.poster.audioClips.unselectAudioPlaylist();
    if (this.poster.mode.isWebpage()) {
      this.openClickableLinkOnItem(e);
    }
  }

  private onMouseUp(): void {
    this.poster.itemsMultiSelect.onMouseUp();
    this.longPressInitiated = false;
    this.canvasPanZoom.onMouseUp();
  }

  private openClickableLinkOnItem(e: CanvasEvents['mouse:down']): void {
    if (e.target) {
      const item = this.items.getItemForFabricObject(e.target);
      if (item && item.hasClickableLink()) {
        redirectUser(item.clickableLink, false, false);
      }
    }
  }

  public getItemLinksMap(): Record<string, string> {
    const uidLinkMap: Record<string, string> = {};

    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if (item.hasClickableLink()) {
        uidLinkMap[item.uid] = item.clickableLink;
      }
    }
    return uidLinkMap;
  }

  private onSelectionCreated(fabricEvent: CanvasEvents['selection:updated']): void {
    if (fabricEvent.e?.type === RESELECT_ITEM_EVENT_TYPE) {
      return;
    }

    this.activeSelection.onSelectionCreated();
    this.poster.audioClips.unselectAudioPlaylist();
    this.items.updateBoundItemsZIndices();
    this.poster.redux.updateSelectedItemsInRedux();
  }

  private onSelectionCleared(fabricEvent: CanvasEvents['selection:cleared']): void {
    if (fabricEvent.e?.type === RESELECT_ITEM_EVENT_TYPE) {
      return;
    }
    this.items.updateBoundItemsZIndices();
    this.poster.itemsMultiSelect.onSelectionCleared();
    this.poster.redux.updateSelectedItemsInRedux();
    this.fabricCanvas.requestRenderAll();
  }

  private onContextMenuStopped(data: CanvasEvents['contextmenu:before']): void {
    this.selectTargetItem(data.target);
    this.openContextualMenu(data);
  }

  private openContextualMenu(data: CanvasEvents['contextmenu:before']): void {
    const {scale} = this.poster.scaling;
    const mouseEvent = data.e as MouseEvent;

    window.PMW.redux.store.dispatch(
      updatePopUpStateAndPosition({
        openPosterPopUpMenu: true,
        popUpPosition: {top: mouseEvent.pageY, left: mouseEvent.pageX},
        target: data.target,
        eventCanvasOffset: {top: mouseEvent.offsetY / scale, left: mouseEvent.offsetX / scale},
      })
    );
  }

  private openTouchContextualMenu(data: HammerInput): void {
    if (data.pointerType === 'mouse') {
      return;
    }
    this.longPressInitiated = true;

    const activeObject = this.activeSelection.getActiveObject();
    if (isMobile && activeObject instanceof Textbox && activeObject.isEditing) {
      updateTextSelection(activeObject);
      handleTextSelectionPopupMenu(activeObject);
      this.fabricCanvas.requestRenderAll();
    } else if (window.PMW.redux.store.getState().posterEditor.isMobileVariant) {
      if (!activeObject || !activeObject.__corner) {
        openPosterEditorMoreOptionsModal();
      }
    } else {
      const {scale} = this.poster.scaling;
      window.PMW.redux.store.dispatch(
        updatePopUpStateAndPosition({
          openPosterPopUpMenu: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore. data.srcEvent is the source event and HammerInput assumes it can only be TouchEvent | MouseEvent | PointerEvent, while in this case it's a fabric event which is why we aren't getting types.
          popUpPosition: {top: data.srcEvent.pageY as number, left: data.srcEvent.pageX as number},
          target: this.activeSelection.getActiveObject(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          eventCanvasOffset: {top: data.srcEvent.offsetY / scale, left: data.srcEvent.offsetX / scale},
        })
      );
    }
  }

  private selectTargetItem(targetObject: FabricObject | undefined): void {
    if (targetObject && this.activeSelection.getActiveObjects().length < 2) {
      this.activeSelection.setActiveObject(targetObject);
    }
  }

  private addCanvasToPoster(): void {
    const html = `<div class='pmw-page-container js-canvas' data-id='${this.hashedID}'>
                    <div class='pmw-pre-page-container'></div>
                    <canvas width='${this.poster.width}' height='${this.poster.height}'/>
                    <div class='pmw-post-page-container'></div>
                  </div>`;

    this.poster.HTMLElement.insertAdjacentHTML('beforeend', html);
    this.fabricCanvas = this.getFabricCanvas();
    this.fabricCanvas.on('selection:updated', this.onSelectionCreated.bind(this));
    this.fabricCanvas.on('selection:created', this.onSelectionCreated.bind(this));
    this.fabricCanvas.on('mouse:down:before', this.poster.itemsMultiSelect.onMouseDownBefore.bind(this.poster.itemsMultiSelect));
    this.fabricCanvas.on('mouse:down', this.onMouseDown.bind(this));
    this.fabricCanvas.on('mouse:up', this.onMouseUp.bind(this));
    this.fabricCanvas.on('selection:cleared', this.onSelectionCleared.bind(this));
    this.fabricCanvas.on('contextmenu:before', this.onContextMenuStopped.bind(this));
    this.fabricCanvas.on('path:created', this.poster.drawing.onPathDrawn.bind(this.poster.drawing));
    this.fabricCanvas.on('object:modified', this.activeSelection.onViewModified.bind(this.activeSelection));
    this.fabricCanvas.on('after:touchstart', this.onAfterTouchStart.bind(this));

    this.fabricCanvas.on('object:moving', this.hideControls.bind(this));
    this.fabricCanvas.on('object:modified', this.showControls.bind(this));

    this.fabricCanvas.on('object:moving', this.onObjectsModifying.bind(this, 'moving'));
    this.fabricCanvas.on('object:rotating', this.onObjectsModifying.bind(this, 'rotating'));
    this.fabricCanvas.on('object:scaling', this.onObjectsModifying.bind(this, 'scaling'));

    this.initUpperCanvasElEvents();
    this.initRenderOnEachFrame();
    this.initRenderOnWindowFocus();
    this.applyScale();
  }

  private hideControls(e: CanvasEvents['object:moving']): void {
    if (e.target) {
      e.target.set({
        hasControls: false,
      });
    }
  }

  private showControls(e: CanvasEvents['object:modified']): void {
    if (e.target) {
      e.target.set({
        hasControls: true,
      });
    }
  }

  private onObjectsModifying(modificationType: 'scaling' | 'moving' | 'rotating', e: CanvasEvents['object:moving' | 'object:rotating' | 'object:scaling']): void {
    if (e.target) {
      if (e.target instanceof ActiveSelection) {
        const group = e.target;
        const activeObjects = group.getObjects();
        for (const activeObject of activeObjects) {
          const itemId = getFabricObjectUID(activeObject);
          const item = window.posterEditor?.whiteboard?.getItemForId(itemId);
          if (item) {
            this.onItemModifying(item, modificationType);
          }
        }
      } else {
        const itemId = getFabricObjectUID(e.target);
        const item = window.posterEditor?.whiteboard?.getItemForId(itemId);
        if (item) {
          this.onItemModifying(item, modificationType);
        }
      }
    }
  }

  private onItemModifying(item: ItemType, modificationType: 'scaling' | 'moving' | 'rotating'): void {
    switch (modificationType) {
      case 'moving':
        item.onMoving();
        return;
      case 'scaling':
        item.onScaling();
        return;
      case 'rotating':
        item.onRotating();
        return;
      default:
        throw new Error('Unhandled modificationType');
    }
  }

  private initRenderOnEachFrame(): void {
    if (!this.poster.mode.isGeneration()) {
      const render = (): void => {
        if (this.isVideo() && this.poster.isPlaying()) {
          this.fabricCanvas.renderAll();
          util.requestAnimFrame(render);
        } else {
          setTimeout(() => {
            util.requestAnimFrame(render);
          }, 100);
        }
      };
      util.requestAnimFrame(render);
    }
  }

  private initRenderOnWindowFocus(): void {
    window.addEventListener('focus', () => {
      this.fabricCanvas.requestRenderAll();
    });
  }

  private getFabricCanvas(): Canvas | StaticCanvas {
    if (this.poster.mode.isGeneration()) {
      return new StaticCanvas(this.getHTMLCanvas(), {
        renderOnAddRemove: false,
        skipControlsDrawing: true,
      });
    }
    return new Canvas(this.getHTMLCanvas(), {
      hoverCursor: 'pointer',
      selection: true,
      uniScaleKey: 'shiftKey',
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
      allowTouchScrolling: true,
      stopContextMenu: true,
      targetFindTolerance: isMobile ? 50 : 10,
    });
  }

  private onCanvasDoubleTap(): void {
    const activeObject = this.activeSelection.getActiveObject();
    if (activeObject instanceof Textbox && activeObject.isEditing) {
      updateTextSelection(activeObject);
      handleTextSelectionPopupMenu(activeObject);
    } else {
      window.PMW.redux.store.dispatch(updateSidebarState(true));
    }
  }

  public dispose(): void {
    void this.fabricCanvas.dispose();
    for (const [eventName, eventFunction] of Object.entries(this.hammerEventFunctions)) {
      this.fabricCanvasHammerManager?.off(eventName, eventFunction as HammerListener);
    }
    this.fabricCanvasHammerManager?.destroy();
  }

  private onAfterTouchStart(e: CanvasEvents['after:touchstart']): void {
    this.poster.fire(PosterEvent.FABRIC_CANVAS_TOUCH_START_DETECTED);
    this.canvasPanZoom.onCanvasAfterTouchStart(e);
  }

  private initUpperCanvasElEvents(): void {
    if (this.fabricCanvas instanceof Canvas) {
      this.fabricCanvasHammerManager = new Hammer.Manager(this.fabricCanvas.upperCanvasEl);
      const longPress = new Hammer.Press({
        event: 'longpress',
        time: 900,
      });
      const singletap = new Hammer.Tap({
        event: 'singletap',
      });

      this.fabricCanvasHammerManager.add([longPress, singletap]);
      this.fabricCanvasHammerManager.on('longpress', this.hammerEventFunctions.longpress);
      this.fabricCanvasHammerManager.on('singletap', this.hammerEventFunctions.singletap);

      if (isMobile) {
        const doubleTap = new Hammer.Tap({
          event: 'doubletap',
          taps: 2,
        });
        this.fabricCanvasHammerManager.add([doubleTap]);
        this.fabricCanvasHammerManager.on('doubletap', this.hammerEventFunctions.doubletap);
      }

      if (isEditorMobileVariant()) {
        const singleFingerPan = new Hammer.Pan({
          event: 'singleFingerPan',
          direction: Hammer.DIRECTION_ALL,
          pointers: 1,
        });
        const twoFingerPan = new Hammer.Pan({
          event: 'twoFingerPan',
          direction: Hammer.DIRECTION_ALL,
          pointers: 2,
        });
        const pinch = new Hammer.Pinch({
          event: 'pinch',
        });

        this.fabricCanvasHammerManager.add([pinch, singleFingerPan, twoFingerPan]);
        this.fabricCanvasHammerManager.on('singleFingerPan', this.hammerEventFunctions.singleFingerPan);
        this.fabricCanvasHammerManager.on('twoFingerPan', this.hammerEventFunctions.twoFingerPan);
        this.fabricCanvasHammerManager.on('pinch', this.hammerEventFunctions.pinch);
      }
    }
  }

  private onSingleTapOnCanvas(): void {
    const canvas = this.fabricCanvas as Canvas;
    const activeObject = canvas.getActiveObject();
    const activeItem = this.getSelectedItems()[0];
    if (activeObject && activeObject instanceof Textbox && (activeItem?.isText() || activeItem?.isSlideshow()) && activeItem.clone && activeItem.clone.hiddenTextarea) {
      activeItem.clone.hiddenTextarea.focus();
    }
  }

  public static getHTMLContainerForPageId(pageId: string): HTMLElement | undefined {
    return $(`.pmw-page-container[data-id=${pageId}]`).get(0);
  }

  public static getFirstCanvasContainerHTML(): HTMLElement | undefined {
    return $(`.pmw-page-container:first .canvas-container`).get(0);
  }

  public static getCanvasContainerHTMLForPageId(pageId: string): HTMLElement | undefined {
    return $(`.pmw-page-container[data-id=${pageId}] .canvas-container`).get(0);
  }

  public static getPrePageHTMLContainerForPageId(pageId: string): HTMLElement | undefined {
    return $(`.pmw-page-container[data-id=${pageId}] .pmw-pre-page-container`).get(0);
  }

  public getHTMLCanvas(): HTMLCanvasElement {
    const htmlCanvas = $(`.pmw-page-container[data-id=${this.hashedID}] canvas`).get(0);
    if (!htmlCanvas) {
      throw new Error('Canvas not found');
    }
    return htmlCanvas as HTMLCanvasElement;
  }

  public getCanvasContainer(): HTMLElement | undefined {
    return Page.getCanvasContainerHTMLForPageId(this.hashedID);
  }

  public toObject(): PageObject {
    return {
      hashedID: this.hashedID,
      items: this.items.toObject(),
      background: this.background.toObject(),
      introAnimation: this.introAnimation.toObject(),
      duration: this.duration,
    };
  }

  public async updateFromObject(
    pageObject: DeepPartial<PageObject> = {},
    {updateRedux = true, undoable = true, refreshActiveSelection = false, checkForDurationUpdate = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    const {items, background, introAnimation, ...pageObjectWithoutItems} = pageObject;
    this.copyVals({
      ...pageObjectWithoutItems,
    });

    if (introAnimation) {
      this.introAnimation.updateFromObject(introAnimation, {undoable: false, checkForDurationUpdate});
    }
    if (background) {
      await this.background.updateFromObject(background, {undoable: false, updateRedux: false});
    }
    if (items) {
      await this.items.updateFromObject(items, {
        undoable: false,
        updateRedux: false,
        refreshActiveSelection,
        checkForDurationUpdate,
      });
    }

    if (pageObject.duration) {
      await this.poster.audioClips.trimAudiosExceedingPageDuration();
    }

    this.pageWatermark.refreshWatermark();
    if (undoable) {
      this.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.poster.redux.updateReduxData();
    }
  }

  public selectAllUnlockedItems = (): void => {
    const fabricObjects = this.fabricCanvas?.getObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }
    this.activeSelection?.clearSelection();
    const objects: Array<ItemFabricObject> = [];
    for (const fabricObj of fabricObjects) {
      const item = this.items.getItem(fabricObj.__PMWID);
      if (item && !item.isLocked()) {
        objects.push(fabricObj);
      }
    }

    this.activeSelection.selectFabricObjects(objects);
  };

  public selectAllItems = (): void => {
    const fabricObjects = this.fabricCanvas?.getObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return;
    }
    this.activeSelection?.clearSelection();

    const objects: Array<ItemFabricObject> = [];
    for (const fabricObj of fabricObjects) {
      const item = this.items.getItem(fabricObj.__PMWID);
      if (item) {
        objects.push(fabricObj);
      }
    }

    this.activeSelection.selectFabricObjects(objects);
  };

  public getSelectedItems(): Array<ItemType> {
    const fabricObjects = this.activeSelection.getActiveObjects();
    if (!fabricObjects || fabricObjects.length === 0) {
      return [];
    }

    const selectedItems = [];
    for (const fabricObj of fabricObjects) {
      const item = this.getItemForFabricObject(fabricObj);
      if (item) {
        selectedItems.push(item);
      }
    }
    return selectedItems;
  }

  public getFilteredExistingViews(views: Array<FabricObject | Group>): Array<FabricObject | Group> {
    const filteredViews = [];
    for (const view of views) {
      if (this.fabricCanvas.getObjects().indexOf(view) !== -1) {
        filteredViews.push(view);
      }
    }
    return filteredViews;
  }

  public getItemForFabricObject(fabricObj: FabricObject): ItemType | undefined {
    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if (item.doesfabricObjBelongtoItem(fabricObj)) {
        return item;
      }
    }
    return undefined;
  }

  public getStreamingItems(): Array<ItemType> {
    const streamingItems = [];
    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if (item.isStreamingMediaItem()) {
        streamingItems.push(item);
      }
    }
    return streamingItems;
  }

  public hasItem(uid: string): boolean {
    return this.items.itemsHashMap[uid] !== undefined;
  }

  public isVideo(): boolean {
    if ((this.introAnimation.hasIntroAnimation() && this.items.hasItems()) || this.poster.audioClips.hasAudio()) {
      return true;
    }
    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if (item.isVideo() || item.isSticker() || item.isTranscript() || (item.isSlideshow() && item.isStreamingItem())) {
        return true;
      }
    }
    return false;
  }

  public isTransparent(): boolean {
    return this.background.isTransparent();
  }

  public isPremium(): boolean {
    if (this.background.isPremium()) {
      return true;
    }

    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if (item.isPremium()) {
        return true;
      }
    }
    return false;
  }

  public async stopPage(): Promise<void> {
    const promises = [];
    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if ('stop' in item) {
        promises.push(item.stop());
      }
    }
    this.introAnimation.stopAnimation();
    await Promise.all(promises);
  }

  public async playPage(): Promise<void> {
    const promises = [];

    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if ('play' in item) {
        promises.push(item.play());
      }
    }

    await Promise.all(promises);
  }

  public onSeekPage(): void {
    this.introAnimation.stopAnimation();
  }

  public async pausePage(): Promise<void> {
    const promises = [];

    for (const [, item] of Object.entries(this.items.itemsHashMap)) {
      if ('pause' in item) {
        promises.push(item.pause());
      }
    }

    this.introAnimation.stopAnimation();
    await Promise.all(promises);
  }

  public getCanvasCornerPoints(): CornerPoints {
    const {scale} = this.poster.scaling;
    return {
      tl: {
        x: 0,
        y: 0,
      },
      tr: {
        x: this.fabricCanvas.width / scale,
        y: 0,
      },
      br: {
        x: this.fabricCanvas.width / scale,
        y: this.fabricCanvas.height / scale,
      },
      bl: {
        x: 0,
        y: this.fabricCanvas.height / scale,
      },
    };
  }

  public setBackgroundColor(color: TFiller | string): void {
    this.fabricCanvas.backgroundColor = color;
  }

  public setBackgroundImage(image: FabricImage | undefined): void {
    this.fabricCanvas.backgroundImage = image;
  }

  public clearSelection(): void {
    this.activeSelection.clearSelection();
  }

  public getColorsOnPage(): Array<string> {
    let colors: Array<RGB> = [];
    if (this.background.details.type === BackgroundTypeName.COLOR) {
      colors = [...this.background.details.fill.fillColor, ...colors];
    }

    const itemsOnPage = this.items.getItems();
    for (let i = 0; i < itemsOnPage.length; i += 1) {
      colors = [...itemsOnPage[i].getColors(), ...colors];
    }

    const colorsOnDesign: Array<string> = [];
    for (const eachColor of colors) {
      colorsOnDesign.push(rgbToHexString(eachColor));
    }

    return colorsOnDesign.filter((color, index) => {
      return colorsOnDesign.indexOf(color) === index;
    });
  }

  public getFontsOnPage(withVariation = false): Array<string> {
    const items = Object.values(this.items.itemsHashMap);
    const fonts: Array<string> = [];

    for (const eachItem of items) {
      if (eachItem.hasEditableText()) {
        fonts.push(...eachItem.getFonts(withVariation));
      }
    }

    return fonts.filter((font, index) => {
      return fonts.indexOf(font) === index;
    });
  }

  public isSingleItemSelected(): boolean {
    const selectedItems = this.getSelectedItems();
    if (!selectedItems) {
      return false;
    }

    return selectedItems.length === 1;
  }

  public validatePremiumUserAccess(): boolean {
    return this.isPremium() && window.PMW.getUserPremiumLevel() === USER_PREMIUM_LEVELS.PAYG;
  }
}
