import {getDirForMediaSizeType, MediaItemSizeType, repoURL} from '@Libraries/s3-library';
import {getCompatibleImageFileExtension} from '@Utils/image.util';
import type {PMWStockImageSourceType, PMWStockMediaSourceType} from '@Libraries/pmw-stock-media-library.types';
import {PMW_STOCK_IMAGE_EXTENSION, PMWStockImageSource, PMWStockMediaSource} from '@Libraries/pmw-stock-media-library.types';
import type {
  PMWStockBackendAnimatedSticker,
  PMWStockBackendEmoji,
  PMWStockBackendExtractedGettySticker,
  PMWStockBackendIcon,
  PMWStockBackendShape,
  PMWStockBackendStillSticker,
} from '@Panels/manage-pmw-stock-panels/manage-pmw-stock-panels-types';
import type {StockButtonFilters} from '@Panels/stock-panel/stock-panel.types';
import {AUDIO_CATEGORIES_REQUEST_LOCAL_STORAGE_CACHE_KEY} from '@Panels/stock-panel/stock-panel.types';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {isProResSupported, isWebmSupported} from '@Utils/browser.util';
import {getReadBucket, getWriteBucket} from '@Utils/s3.util';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';

const BASE_DIR = 'pmwstockmedia';
const STICKER_IMAGE_THUMB_EXTENSION = 'webp';
const SHAPE_IMAGE_THUMB_EXTENSION = 'webp';
const SHAPE_IMAGE_THUMB_FILENAME_SUFFIX = '_thumb';
const DEFAULT_STICKER_EXTENSION = 'webm';
const TRANSPARENT_VIDEO_FALLBACK_EXTENSION = 'mov';
const NON_TRANSPARENT_VIDEO_FALLBACK_EXTENSION = 'mp4';
const STICKER_THUMB_EXTENSION = 'gif';
const STICKERS_UPDATED_ON = 1615206102;

export const GENERATE_IMAGE_INSUFFICIENT_CREDITS_RESPONSE_CODE = 'insufficient_credits';
export const GENERATE_IMAGE_FLAGGED_PROMPT_RESPONSE_CODE = 'flagged_prompt';

export enum StockSources {
  PMW_ICON = 'icon',
  PMW_STILL_STICKER = 'still_sticker',
  PMW_EXTRACTED_GETTY_STICKER = 'extracted_getty_sticker',
  PMW_EMOJI = 'emoji',
  PMW_STOCK_IMAGE = 'image',
  PMW_ANIMATED_STICKER = 'animated_sticker',
  PMW_SHAPE = 'shape',
  GETTY_IMAGE = 'GETTY_IMAGE',
  GETTY_VIDEO = 'GETTY_VIDEO',
  GETTY_ILLUSTRATION = 'GETTY_ILLUSTRATION',
  STORYBLOCK_IMAGE = 'STORYBLOCK_IMAGE',
  STORYBLOCK_VIDEO = 'STORYBLOCK_VIDEO',
  STORYBLOCK_ILLUSTRATION = 'STORYBLOCK_ILLUSTRATION',
  STORYBLOCK_AUDIO = 'STORYBLOCK_AUDIO',
  PIXABY_IMAGE = 'PIXABY_IMAGE',
  PIXABY_VIDEO = 'PIXABY_VIDEO',
  PIXABY_ILLUSTRATION = 'PIXABY_ILLUSTRATION',
}

export enum AspectRatio {
  SQUARE = 'SQUARE',
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export type StockOffsets = Record<StockSources, number>;

export type LazyLoadingOffsets = Record<any, number>;

export interface SubCategoryBackendData {
  displayName: string;
  id: number;
  idCategory: string;
}

export interface AudioCategoryBackendData {
  contentType: string;
  displayName: string;
  id: string;
  subcategories: Array<SubCategoryBackendData>;
}

export interface StockGetterOptions {
  // CodeReviewUsamaAsk: Why is the name audioFilters? Rename it to fitlers. Why not combine this and the video4k key below?
  audioFilters?: Record<StockButtonFilters, Array<string>>;
  only4KVideos?: boolean;
}

export interface StockGetterParams extends LazyLoadingGetterParams {
  offsets: StockOffsets;
  options?: StockGetterOptions;
}

export interface LazyLoadingGetterParams {
  limit: number;
  offsets: LazyLoadingOffsets;
  searchTerm?: string;
  options?: Record<string, any>;
}

export interface LazyLoadingResponse {
  response: Array<any>;
  offsetsData: LazyLoadingOffsets;
}

export enum StabilityAIStylePreset {
  PHOTOGRAPHIC = 'photographic',
  DIGITAL_ART = 'digital-art',
  THREE_D_MODEL = '3d-model',
  CINEMATIC = 'cinematic',
  COMIC_BOOK = 'comic-book',
  NEON_PUNK = 'neon-punk',
  LINE_ART = 'line-art',
  FANTASY_ART = 'fantasy-art',
  ANIME = 'anime',
  PIXEL_ART = 'pixel-art',
  ANALOG_FILM = 'analog-film',
}

export interface GenerateImageParams {
  prompt: string;
  aspectRatio?: AspectRatio;
  stylePresets?: StabilityAIStylePreset;
}

export type StockBackendData =
  | PMWStockBackendShape
  | PMWStockBackendAnimatedSticker
  | PMWStockBackendStillSticker
  | PMWStockBackendEmoji
  | PMWStockBackendIcon
  | PMWStockBackendExtractedGettySticker;

export interface StockResponse {
  offsets: StockOffsets;
  stock: Array<StockBackendData>;
}

export interface AIGenerateImageBackendVO {
  id: number;
  filename: string;
  ext: string;
  src: string;
  hasTransparency: boolean;
  uploaderId: number;
  uploaderName: string;
  width: string;
  height: string;
  text: string;
  style: string;
  idPrompt: number;
  idAIGeneratedImage: number;
  isAddedOnDesign: boolean;
}

export interface BaseImageResponseBackendData {
  src: string;
  ext: string;
  height: number;
  width: number;
}

export interface AITextToImageBackendData {
  id: string;
  prompt: AITextToImagePromptBackendData;
  hashedFilename: string;
  addedToDesign: boolean;
  imageProps: BaseImageResponseBackendData;
  uploaderId: number;
  uploaderName: string;
}

export interface AITextToImagePromptBackendData {
  idPrompt: string;
  text: string;
  style?: string;
  aspect_ratio?: string;
}

export interface GenerateAIImageResponse {
  remainingCredits: number;
  generatedImages: Array<AITextToImageBackendData>;
}

interface GetStockForCarouselParam {
  stockSrcToOffsetMap: Partial<StockOffsets>;
}

export const getPMWStockAnimatedStickerThumbURL = (hashedFilename: string): string => {
  return repoURL(
    `${getDirPathForPMWStockMedia(PMWStockMediaSource.PMW_ANIMATED_STICKER, MediaItemSizeType.THUMB)}/${hashedFilename}.${getPMWStockAnimatedStickerThumbExtension()}`,
    getWriteBucket()
  );
};

export const getPMWStockShapeThumbURL = (hashedFilename: string): string => {
  return repoURL(
    `${getDirPathForPMWStockMedia(
      PMWStockMediaSource.PMW_SHAPE,
      MediaItemSizeType.HIGH_RES
    )}/${hashedFilename}${SHAPE_IMAGE_THUMB_FILENAME_SUFFIX}.${getCompatibleImageFileExtension(SHAPE_IMAGE_THUMB_EXTENSION)}`,
    getWriteBucket()
  );
};

export const getPMWStockIconThumbURL = (hashedFilename: string): string => {
  return getUrlForPMWStockImage(hashedFilename, PMWStockImageSource.PMW_ICON, MediaItemSizeType.THUMB);
};

export const getPMWStockExtractedGettyStickerThumbURL = (hashedFilename: string): string => {
  return getUrlForPMWStockImage(hashedFilename, PMWStockImageSource.PMW_EXTRACTED_GETTY_STICKER, MediaItemSizeType.THUMB);
};

export const getPMWStockStillStickerThumbURL = (hashedFilename: string): string => {
  return getUrlForPMWStockImage(hashedFilename, PMWStockImageSource.PMW_STILL_STICKER, MediaItemSizeType.THUMB);
};

export const getPMWStockImageThumbURL = (hashedFilename: string): string => {
  return getUrlForPMWStockImage(hashedFilename, PMWStockImageSource.PMW_STOCK_IMAGE, MediaItemSizeType.THUMB);
};

export const getPMWStockEmojiThumbURL = (hashedFilename: string): string => {
  return getUrlForPMWStockImage(hashedFilename, PMWStockImageSource.PMW_EMOJI, MediaItemSizeType.THUMB);
};

export const repoPMWStockAnimatedStickerThumbImageURL = (hashedFilename: string): string => {
  return repoURL(
    `${getDirPathForPMWStockMedia(PMWStockMediaSource.PMW_ANIMATED_STICKER, MediaItemSizeType.THUMB)}/${hashedFilename}.${STICKER_IMAGE_THUMB_EXTENSION}?ts=${STICKERS_UPDATED_ON}`,
    getWriteBucket()
  );
};

export const repoPMWStockAnimatedStickerScreenURL = (hashedFilename: string): string => {
  return repoURL(
    `${getDirPathForPMWStockMedia(
      PMWStockMediaSource.PMW_ANIMATED_STICKER,
      MediaItemSizeType.SCREEN
    )}/${hashedFilename}.${getPMWStockAnimatedStickerExtension()}?ts=${STICKERS_UPDATED_ON}`,
    getReadBucket()
  );
};

export const repoPMWStockAnimatedStickerHighResURL = (hashedFilename: string): string => {
  return repoURL(
    `${getDirPathForPMWStockMedia(
      PMWStockMediaSource.PMW_ANIMATED_STICKER,
      MediaItemSizeType.HIGH_RES
    )}/${hashedFilename}.${getPMWStockAnimatedStickerExtension()}?ts=${STICKERS_UPDATED_ON}`,
    getReadBucket()
  );
};

const getPMWStockAnimatedStickerThumbExtension = (): string => {
  return STICKER_THUMB_EXTENSION;
};

const getPMWStockAnimatedStickerExtension = (): string => {
  if (isWebmSupported()) {
    return DEFAULT_STICKER_EXTENSION;
  }

  if (isProResSupported()) {
    return TRANSPARENT_VIDEO_FALLBACK_EXTENSION;
  }

  return NON_TRANSPARENT_VIDEO_FALLBACK_EXTENSION;
};

export const getUrlForPMWStockImage = (hashedFilname: string, source: PMWStockImageSourceType, sizeType: MediaItemSizeType): string => {
  return repoURL(`${getDirPathForPMWStockMedia(source, sizeType)}/${getPMWStockImageFilename(hashedFilname)}`, getWriteBucket());
};

export const getPMWStockImageFilename = (hashedFilname: string): string => {
  return `${hashedFilname}.${getCompatibleImageFileExtension(PMW_STOCK_IMAGE_EXTENSION)}`;
};

export const getDirPathForPMWStockMedia = (source: PMWStockMediaSourceType, sizeType: MediaItemSizeType): string => {
  let stockDir = '';
  switch (source) {
    case PMWStockMediaSource.PMW_ICON:
      stockDir = 'pmwstockicons';
      break;
    case PMWStockMediaSource.PMW_EXTRACTED_GETTY_STICKER:
      stockDir = 'pmwstockextractedgettystickers';
      break;
    case PMWStockMediaSource.PMW_STILL_STICKER:
      stockDir = 'pmwstockstillstickers';
      break;
    case PMWStockMediaSource.PMW_STOCK_IMAGE:
      stockDir = 'pmwstockimages';
      break;
    case PMWStockMediaSource.PMW_ANIMATED_STICKER:
      stockDir = 'pmwstockanimatedstickers';
      break;
    case PMWStockMediaSource.PMW_SHAPE:
      stockDir = 'pmwstockshapes';
      break;
    default:
      throw new Error(`unhandled PMWStockMediaSource: ${source}`);
  }

  const subDirForSize = getDirForMediaSizeType(sizeType);
  return `${BASE_DIR}/${stockDir}${subDirForSize ? `/${subDirForSize}` : ''}`;
};

export const getAllStock = async (p: StockGetterParams, key: string | undefined = undefined): Promise<StockResponse> => {
  return (await window.PMW.readLocal('stockmedia/getStock', p, key, 600, 4 * 24 * 3600000)) as StockResponse;
};

export const getStockForCarousel = async (p: GetStockForCarouselParam): Promise<StockResponse> => {
  return (await window.PMW.readLocal('stockmedia/getStockForCarousel', p, undefined, 600, 4 * 24 * 3600000)) as StockResponse;
};

export const getStockLibraryAudioCategories = async (): Promise<Array<AudioCategoryBackendData>> => {
  return (await window.PMW.readLocal('posterbuilder/getStockLibraryAudioCategories', {}, AUDIO_CATEGORIES_REQUEST_LOCAL_STORAGE_CACHE_KEY)) as Array<AudioCategoryBackendData>;
};

export const setRecentStockItem = async (stockItem: GridItemStorage): Promise<number> => {
  return (await window.PMW.writeLocal('stockmedia/addItemToRecents', {stockItem})) as number;
};

export const getRecentStockItems = async (): Promise<Array<GridItemStorage>> => {
  return (await window.PMW.readLocal('stockmedia/getRecentStockItems')) as Array<GridItemStorage>;
};

export const generateImage = async (generateImageParams: GenerateImageParams): Promise<GenerateAIImageResponse> => {
  trackPosterBuilderGA4Events(GA4EventName.AI_IMAGE_TOTAL_GENERATIONS);
  return (await window.PMW.writeLocal('stockmedia/generateImage', generateImageParams)) as GenerateAIImageResponse;
};

export const getGeneratedImagesForCarousel = async (): Promise<Array<AITextToImageBackendData>> => {
  return (await window.PMW.readLocal('stockmedia/getGeneratedImagesForCarousel')) as Array<AITextToImageBackendData>;
};

export const getUserGeneratedImages = async (offset: number, limit: number): Promise<Array<AITextToImageBackendData>> => {
  return (await window.PMW.readLocal('stockmedia/getGeneratedImages', {limit, offset})) as Array<AITextToImageBackendData>;
};

export const getRemainingTextToImageUsages = async (): Promise<number> => {
  return (await window.PMW.readLocal('stockmedia/getRemainingTextToImageCredits')) as number;
};
