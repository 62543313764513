import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import md5 from 'md5';
import type {PosterLoadObject} from '@PosterWhiteboard/poster/poster.types';
import {getPosterBackendObjectFromPoster} from '@PosterWhiteboard/poster/poster-frontend-to-backend';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {openMessageModal} from '@Modals/message-modal';
import {MESSAGE_TYPE} from '@Panels/message-panel';
import {authenticateUser, geCurrentUserData, getUserId, logOutUser} from '@Libraries/user.library';
import {openSaveConflictModal} from '@Modals/save-conflict-modal';
import {getEditPosterURL} from '@Libraries/poster-editor.library';
import {getPosterObjectFromPosterBackendObject} from '@PosterWhiteboard/poster/poster-backend';
import type {PosterBackendObject} from '@PosterWhiteboard/poster/poster-backend.types';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {showMessageGrowl} from '@Components/message-growl/message-growl';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {User} from '@PosterWhiteboard/user/user.class';
import * as jsondiffpatch from 'jsondiffpatch';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import {areCookiesEnabled} from '@Utils/cookie.util';
import {logClientError} from '@Libraries/log.library';
import {PAGE_WATERMARK_MODE} from '../page/page-watermark.class';

const PREVIEW_SCREEN_LARGER_DIMENSION = 690;
const SAVED_NOTIFICATION_TIMOUT_DURATION = 5000;

interface SavePosterOpts {
  isInTeamSpace?: boolean;
  ignoreConflict?: boolean;
  waitForPreviewToSave?: boolean;
  showLoadingToast?: boolean;
}

interface SaveResponse {
  folder: any;
  poster: PosterBackendObject;
}

interface SaveErrorResponse {
  data?: SaveErrorData;
}

interface SaveErrorData {
  isUserContributor: boolean;
  user: 'cannot-edit-poster' | 'cannot-copy-poster' | 'not-logged-in' | 'save-conflict' | 'rejected-template';
  conflictUser: {
    name: string;
  };
  rejectionTitle: string;
  rejectionText: string;
}

interface SaveData {
  ignoreConflict: number;
  isInTeamSpace: number;
  posterData: string;
  checksum: string;
  idFolder?: string;
  debugInfo?: object; // todo umar: temp for logging
}

export const SAVE_FAILED_DUE_TO_INCOMPLETE_IMAGE_UPLOAD_ERROR_SUBSTRING = 'no hashedFilename or fileExtension found for image with uid:';

export class SavePoster {
  private lastSavedPosterObject?: PosterLoadObject;

  private readonly poster: Poster;

  constructor(poster: Poster) {
    this.poster = poster;
    window.addEventListener('beforeunload', this.onUnloadWindow.bind(this));
  }

  public updateSavedPosterObject(): void {
    this.lastSavedPosterObject = this.poster.toObjectForLoad();
  }

  public hasUnsavedChanges(): boolean {
    if (!this.lastSavedPosterObject) {
      return true;
    }

    const jsonPatchInstance = this.getJsonPatchInstanceForSavePoster();
    try {
      return jsonPatchInstance.diff(this.lastSavedPosterObject, this.poster.toObjectForLoad()) !== undefined;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public disableTabClosePrompt(): void {
    window.removeEventListener('beforeunload', this.onUnloadWindow.bind(this));
  }

  public async save(
    {isInTeamSpace = false, ignoreConflict = false, waitForPreviewToSave = false, showLoadingToast = true}: SavePosterOpts = {},
    onCloseAuthenticateModal?: () => void
  ): Promise<void> {
    if (!this.hasUnsavedChanges() || this.poster.areAnyImagesUploading()) {
      return;
    }
    await authenticateUser(
      this.doSave.bind(this, {
        isInTeamSpace,
        ignoreConflict,
        waitForPreviewToSave,
        showLoadingToast,
      }),
      onCloseAuthenticateModal
    );
  }

  public async doSave({isInTeamSpace = false, ignoreConflict = false, waitForPreviewToSave = false, showLoadingToast = true}: SavePosterOpts = {}): Promise<void> {
    if (showLoadingToast) {
      showLoading('savingPoster', {
        text: window.i18next.t('pmwjs_saving'),
      });
    }
    try {
      await this.doSavePoster(ignoreConflict, isInTeamSpace);
      this.updateUrl();
      this.poster.updateTitle();
      if (waitForPreviewToSave) {
        await this.savePosterPreview();
      } else {
        this.savePosterPreview().catch((e) => {
          console.error(e);
        });
      }
      this.onPosterSaved(showLoadingToast);
    } catch (e) {
      if (this.isSaveErrorDueToIncompleteImageUploads(e)) {
        void logClientError(e, {
          posterReduxData: {...window.PMW.redux.store.getState().posterEditor},
        });
        void this.onSaveError();
      } else {
        const error = e as SaveErrorResponse;
        void this.onSaveError(error.data);
      }
    } finally {
      if (showLoadingToast) {
        hideLoading('savingPoster');
      }
    }
  }

  private onPosterSaved(doShowMessageGrowl = true): void {
    if (this.poster.mode.details.type === PosterModeType.REGEN) {
      window.location.href = window.PMW.util.site_url(`cart/requestDownloadRegen/${this.poster.mode.details.orderId}`);
      return;
    }
    if (doShowMessageGrowl) {
      showMessageGrowl({
        text: window.i18next.t('pmwjs_saved_successfully'),
        key: 'savedPoster',
        interval: SAVED_NOTIFICATION_TIMOUT_DURATION,
      });
    }
    void geCurrentUserData().then((userData: Partial<UserObject> | undefined) => {
      if (userData?.verificationNeededStatus) {
        window.location.href = window.PMW.util.site_url('authenticate/verificationneeded?saved=1');
      } else {
        window.PMW.premiumDialogs.openTrialDialog(true, 'save');
      }
    });

    this.poster.resizePoster.copyVals(this.poster.toObject());

    if (window.PMW.isEmbedded()) {
      openMessageModal({
        width: '400px',
        height: '270px',
        title: window.i18next.t('pmwjs_poster_saved'),
        text: window.i18next.t('pmwjs_close_editor_safely'),
        showIcon: false,
        ctaButton: {
          text: window.i18next.t('pmwjs_close_editor'),
          onClick: (): void => {
            window.parent.postMessage('Close~iframe', '*');
          },
        },
      });
    }

    trackPosterBuilderGA4Events(GA4EventName.SAVED_POSTER);
  }

  private onUnloadWindow(e: BeforeUnloadEvent): string | undefined {
    if (this.alertBeforeClosing()) {
      e.preventDefault();
      e.returnValue = null;
      return window.i18next.t('pmwjs_onUnloadWindow');
    }
    return undefined;
  }

  private alertBeforeClosing(): boolean {
    return !window.PMW.isStorybook() && !this.poster.mode.isGeneration() && this.hasUnsavedChanges();
  }

  private async savePosterPreview(): Promise<void> {
    const img = this.poster.getCurrentPage().pageSnapshot.getSnapshot({
      scale: PREVIEW_SCREEN_LARGER_DIMENSION / (this.poster.width > this.poster.height ? this.poster.width : this.poster.height),
      enableTransparency: false,
      mode: PAGE_WATERMARK_MODE.NONE,
    });
    await window.PMW.write(window.PMW.util.site_url('posterbuilder/savePreview'), {
      id: this.poster.hashedID,
      checksum: md5(img),
      img,
    });
  }

  private async doSavePoster(ignoreConflict: boolean, isInTeamSpace: boolean): Promise<void> {
    this.poster.deleteItemsNotVisibleOnPoster(false);
    // We're guaranteed a logged-in user. Use their info.
    this.poster.idLastModifier = getUserId()?.toString() as string;
    // todo umar: temp for logging
    let creatorInfoFlow: string = 'normal';
    const originalUserIdInCookie = window.PMW.getUserId();
    let userDataFromServer: Partial<UserObject> | undefined;

    if (this.poster.creator === undefined) {
      creatorInfoFlow = 'undefined';
      const userData = (await geCurrentUserData()) as Partial<UserObject>;
      this.poster.creator = new User(userData);
    } else if (this.poster.creator.id === '-1') {
      creatorInfoFlow = 'defaultId';
      userDataFromServer = (await geCurrentUserData()) as Partial<UserObject> | undefined;
      this.poster.creator?.copyVals(userDataFromServer);
    }
    this.poster.setMenuItemsWrappingInfo();
    this.poster.validatePagesIntroAnimation();
    const posterDataJson = JSON.stringify({
      poster: getPosterBackendObjectFromPoster(this.poster),
    });
    const data: SaveData = {
      ignoreConflict: ignoreConflict ? 1 : 0,
      isInTeamSpace: isInTeamSpace ? 1 : 0,
      posterData: posterDataJson,
      checksum: md5(posterDataJson),
    };

    if (this.poster.folder) {
      data.idFolder = this.poster.folder.id;
    }

    if (this.poster.creator?.id === '-1') {
      // todo umar: temp for logging
      data.debugInfo = {
        creatorInfoFlow,
        isEmbedded: window.PMW.isEmbedded() ? 1 : 0,
        userDataFromServer: {
          id: userDataFromServer?.id,
          isNull: userDataFromServer === undefined ? 1 : 0,
        },
        cookieUserId: window.PMW.getUserId(),
        originalUserIdInCookie,
        areCookiesEnabled: areCookiesEnabled(),
      };
    }
    const response = (await window.PMW.writeLocal('posterbuilder/savePosterData', data)) as SaveResponse;
    const posterObject = getPosterObjectFromPosterBackendObject(response.poster);
    const {pages, ...posterObjectWithoutPages} = posterObject;
    await this.poster.updateFromObject(posterObjectWithoutPages, {updateRedux: false});
    this.lastSavedPosterObject = this.poster.toObjectForLoad();
    this.poster.redux.updateReduxData();

    if (window.PMW.isEmbedded()) {
      window.parent.postMessage(`Save~${this.poster.hashedID}`, '*');
    }
  }

  private async onSaveError(saveErrorData?: SaveErrorData): Promise<void> {
    if (!saveErrorData) {
      openMessageModal({
        type: MESSAGE_TYPE.DANGER,
        text: window.i18next.t('pmwjs_save_poster_error'),
      });
      return;
    }

    switch (saveErrorData.user) {
      case 'cannot-edit-poster': {
        if (saveErrorData.isUserContributor) {
          openMessageModal({
            title: window.i18next.t('pmwjs_permission_denied'),
            text: window.i18next.t('pmwjs_edit_permission_denied'),
          });
          return;
        }

        openMessageModal({
          title: window.i18next.t('pmwjs_design_belongs_to_another_user_title'),
          text: window.i18next.t('pmwjs_design_belongs_to_another_user'),
        });
        return;
      }
      case 'cannot-copy-poster':
        openMessageModal({
          title: window.i18next.t('pmwjs_permission_denied'),
          text: window.i18next.t('pmwjs_copy_permission_denied'),
        });
        return;

      case 'not-logged-in':
        await this.handleLoggedOutUser(window.i18next.t('pmwjs_logged_out_title'), window.i18next.t('pmwjs_logged_out_message'));
        return;

      case 'save-conflict':
        if (saveErrorData.conflictUser) {
          this.onSaveConflict(saveErrorData.conflictUser.name);
        }
        break;

      case 'rejected-template':
        openMessageModal({
          title: saveErrorData.rejectionTitle,
          text: saveErrorData.rejectionText,
        });
        return;

      default:
        openMessageModal({
          type: MESSAGE_TYPE.DANGER,
          text: window.i18next.t('pmwjs_save_poster_error'),
        });
    }
  }

  /**
   * After a poster is saved, makes sure the URL is updated to include the poster's hashed ID and uses the /load
   * server-side method used for editing the poster. This allows the user to copy the URL to edit their poster in the
   * future.
   */
  private updateUrl(): void {
    if (!window.PMW.isStorybook() && window.history && window.location && window.location.pathname && window.location.pathname.length > 0) {
      // The regex expression removes any leading slash in the page path
      const path = window.PMW.util.getCurrentPagePath().replace(/^\/(.*)/, '$1');
      const pathSegments: Array<string> = path.split('/');
      const separator = window.PMW.util.getPagePathSeparatorToken();
      const domain = window.location.pathname.split(separator)[0];
      let c = '';
      let m = '';
      const newPath = [];

      newPath.push(domain + separator);

      if (pathSegments !== undefined && pathSegments[0]) {
        [c] = pathSegments;
      }
      if (pathSegments !== undefined && pathSegments[1]) {
        [, m] = pathSegments;
      }

      // don't update the URL if it was already the load URL
      if (c.length > 0 && m !== 'load') {
        newPath.push(c);
        newPath.push('load');
        newPath.push(this.poster.hashedID);

        let loadUrl = newPath.join('/');

        if (window.PMW.isEmbedded()) {
          const embeddedParams = window.PMW.util.getEmbeddedParamsFromUrlAsArray();
          if (embeddedParams) {
            loadUrl += '?';
            embeddedParams.forEach(([key, value]) => {
              loadUrl = `${loadUrl + key}=${value}&`;
            });
            loadUrl = loadUrl.substring(0, loadUrl.length - 1);
          }
        }

        window.history.replaceState('saved', 'saved', loadUrl);
        this.poster.history.reset();
      }
    }
  }

  /**
   * Displays a message informing the user that they are not logged in and must log in before they can save.
   * When this dialog is closed, the authentication dialog is opened.
   */
  private async handleLoggedOutUser(title: string, text: string): Promise<void> {
    try {
      await logOutUser();
    } catch (e) {
      console.error('error in logging user out. Details: ');
      console.error(e);
    }
    return new Promise((resolve) => {
      openMessageModal({
        title,
        text,
        onClose: authenticateUser.bind(null, async () => {
          await this.save();
          resolve();
        }),
      });
    });
  }

  private getJsonPatchInstanceForSavePoster(): jsondiffpatch.DiffPatcher {
    return jsondiffpatch.create({
      // IMPORTANT: Phpstorm warning of unused property is wrong. Don't Remove this, it is used by the library
      propertyFilter: (name: string, context: jsondiffpatch.DiffContext) => {
        if (name === 'isPlaying' && context.childName === 'audioPlayer') {
          return false;
        }

        return true;
      },
    });
  }

  private onSaveConflict(userName: string): void {
    openSaveConflictModal({
      conflictWith: userName,
      saveTheirsCallback: () => {
        this.disableTabClosePrompt();
        window.location.href = getEditPosterURL(this.poster.hashedID);
      },
      saveYoursCallback: () => {
        void this.save({
          ignoreConflict: true,
        });
      },
    });
  }

  private isSaveErrorDueToIncompleteImageUploads(e: unknown): boolean {
    return !!(typeof e === 'object' && e && 'message' in e && typeof e.message === 'string' && e.message.includes(SAVE_FAILED_DUE_TO_INCOMPLETE_IMAGE_UPLOAD_ERROR_SUBSTRING));
  }
}
