import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {ImageBackgroundObject} from '@PosterWhiteboard/page/background/image-background.class';
import type {ItemObject, ItemType} from '@PosterWhiteboard/items/item/item.types';
import type {PosterLoadObject, PosterObject} from '@PosterWhiteboard/poster/poster.types';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {ImageBackgroundItemObject} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';
import {cloneDeep} from 'lodash';
import {cloneItem} from '@PosterWhiteboard/items/item/item-factory';

// TODO: Improve the readablity of this code
export interface ResizeReferenceObject {
  width: number;
  height: number;
  graphicItems: Record<string, ItemObject>;
  background: Record<string, ImageBackgroundItemObject>;
}

interface DimesionsAndCoordinates {
  width: number;
  height: number;
  x: number;
  y: number;
}

export class ResizePoster extends CommonMethods {
  public poster: Poster;
  public width = -1;
  public height = -1;
  public referenceGraphicItems: Record<string, ItemObject> = {};
  public referenceBackground: Record<string, ImageBackgroundItemObject> = {};

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  public updateResizeReference = (poster: PosterLoadObject): void => {
    const resizeData = getResizeDataFromPoster(poster);
    this.width = resizeData.width;
    this.height = resizeData.height;
    this.referenceGraphicItems = resizeData.graphicItems;
    this.referenceBackground = resizeData.background;
  };

  public toObject(): ResizeReferenceObject {
    return {
      width: this.width,
      height: this.height,
      graphicItems: this.referenceGraphicItems,
      background: this.referenceBackground,
    };
  }

  private resizeToReferencePoster(): PosterObject | null {
    if (!(this.poster.width === this.width && this.poster.height === this.height)) {
      const posterObject = this.getUpdatedPosterForCornerItems();
      if (posterObject) {
        return posterObject;
      }

      const updatedPoster = this.getPosterWithRelocatedItems();
      updatedPoster.width = this.width;
      updatedPoster.height = this.height;
      return updatedPoster;
    }
    return null;
  }

  /**
   *  See if any point of the item is outside the resizeReference canvas;
   *  if a point doesn't lie in a canvas then check if the graphic item exists in referenceSize or not. If not invalidate reference size otherwise check the difference
   *  of corner points of the graphic item in the saved and new resizeReference. If the difference is greater than 10px then invalidate resizeReference.
   */
  private shouldUpdateResizeReference(pageId: string, item: ItemType): boolean {
    const cornerPoints = item.getCornerPoints();

    for (const point of Object.keys(cornerPoints)) {
      const key = point as keyof typeof cornerPoints;
      if (cornerPoints[key].x < 0 || cornerPoints[key].x > this.width || cornerPoints[key].y < 0 || cornerPoints[key].y > this.height) {
        if (!this.referenceGraphicItems[item.uid]) {
          return true;
        }
        const oldItem = this.poster.pages.pagesHashMap[pageId].items.itemsHashMap[item.uid];
        const oldCornerPoints = oldItem.getCornerPoints();
        for (const oldPoint of Object.keys(oldCornerPoints)) {
          const oldKey = oldPoint as keyof typeof cornerPoints;
          if (Math.abs(cornerPoints[oldKey].x - oldCornerPoints[oldKey].x) > 10 || Math.abs(cornerPoints[oldKey].y - oldCornerPoints[oldKey].y) > 10) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private getUpdatedPosterForCornerItems(): PosterObject | null {
    const currentWidth = this.poster.width;
    const currentHeight = this.poster.height;
    const referenceAspectRatio = this.width / this.height;
    const params = getParametersForResizing(currentWidth, currentHeight, referenceAspectRatio);
    const scaleFactor = this.height / params.height;
    const posterObject = this.poster.toObject();

    const pages = posterObject.pages.pagesHashMap;
    for (const pageKey of Object.keys(pages)) {
      const items = pages[pageKey].items.itemsHashMap;
      for (const itemKey of Object.keys(items)) {
        const item = this.poster.pages.pagesHashMap[pageKey].items.itemsHashMap[itemKey];
        const updatedItemClone = cloneItem(this.poster.pages.pagesHashMap[pageKey], item);

        if (updatedItemClone.isText()) {
          const horizontalShift = updatedItemClone.getHorizontalDisplacementForVersion2();
          const verticalShift = updatedItemClone.getVerticalDisplacementForVersion2();

          updatedItemClone.x += Math.floor(params.x - (updatedItemClone.x / params.width) * this.width - (horizontalShift / params.width) * this.width);
          updatedItemClone.y += Math.floor(params.y - (updatedItemClone.y / params.height) * this.height - (verticalShift / params.height) * this.height);
        } else {
          updatedItemClone.x = Math.floor(((updatedItemClone.x - params.x) / params.width) * this.width);
          updatedItemClone.y = Math.floor(((updatedItemClone.y - params.y) / params.height) * this.height);
        }
        updatedItemClone.updateSize(scaleFactor);

        if (this.shouldUpdateResizeReference(pageKey, updatedItemClone)) {
          this.updateResizeReference(posterObject);
          return posterObject;
        }
      }
    }
    return null;
  }

  private getPosterWithRelocatedItems(): PosterObject {
    const currentWidth = this.poster.width;
    const currentHeight = this.poster.height;
    const referenceAspectRatio = this.width / this.height;
    const params = getParametersForResizing(currentWidth, currentHeight, referenceAspectRatio);
    const scaleFactor = this.height / params.height;
    const posterObject = this.poster.toObject();

    const pages = posterObject.pages.pagesHashMap;
    for (const pageKey of Object.keys(pages)) {
      const items = pages[pageKey].items.itemsHashMap;
      for (const itemKey of Object.keys(items)) {
        const item = this.poster.pages.pagesHashMap[pageKey].items.itemsHashMap[itemKey];

        if (item.isText()) {
          const horizontalShift = item.getHorizontalDisplacementForVersion2();
          const verticalShift = item.getVerticalDisplacementForVersion2();

          item.x += Math.floor(params.x - (item.x / params.width) * this.width - (horizontalShift / params.width) * this.width);
          item.y += Math.floor(params.y - (item.y / params.height) * this.height - (verticalShift / params.height) * this.height);
        } else {
          item.x = Math.floor(((item.x - params.x) / params.width) * this.width);
          item.y = Math.floor(((item.y - params.y) / params.height) * this.height);
        }

        item.updateSize(scaleFactor);
        items[itemKey] = item.toObject();
      }

      const background = pages[pageKey].background.details;
      if (background.type === BackgroundTypeName.IMAGE) {
        background.imageBackgroundItemObject = cloneDeep(this.referenceBackground[background.imageBackgroundItemObject.uid]);
        posterObject.pages.pagesHashMap[pageKey].background.details = background;
      }
    }

    return posterObject;
  }

  private resizePosterToTargetType(updatedPoster: PosterObject, pageKey: string, posterType: PosterTypeObject): void {
    const currentWidth = updatedPoster.width;
    const currentHeight = updatedPoster.height;
    const currentAspectRatio = currentWidth / currentHeight;

    const params = getParametersForResizing(posterType.width, posterType.height, currentAspectRatio);
    const scaleFactor = params.height / currentHeight;
    const resizeXAdjust = currentWidth - params.width;
    const resizeYAdjust = currentHeight - params.height;

    const page = updatedPoster.pages.pagesHashMap[pageKey];
    for (const itemKey of Object.keys(page.items.itemsHashMap)) {
      const itemObject = page.items.itemsHashMap[itemKey];
      const item = this.poster.pages.pagesHashMap[pageKey].items.itemsHashMap[itemObject.uid];

      if (item.isText()) {
        const horizontalShift = item.getHorizontalDisplacementForVersion2();
        const verticalShift = item.getVerticalDisplacementForVersion2();

        item.x += Math.floor(params.x - (item.x / currentWidth) * resizeXAdjust - (horizontalShift / currentWidth) * resizeXAdjust);
        item.y += Math.floor(params.y - (item.y / currentHeight) * resizeYAdjust - (verticalShift / currentHeight) * resizeYAdjust);
      } else {
        item.x += Math.floor(params.x - (item.x / currentWidth) * resizeXAdjust);
        item.y += Math.floor(params.y - (item.y / currentHeight) * resizeYAdjust);
      }
      item.updateSize(scaleFactor);
      page.items.itemsHashMap[itemKey] = item.toObject();
    }
  }

  public getResizedPosterObjectForPosterType(posterType: PosterTypeObject): PosterObject {
    const updatedPoster = this.resizeToReferencePoster() ?? this.poster.toObject();

    const currentWidth = updatedPoster.width;
    const currentHeight = updatedPoster.height;

    if (!(currentWidth === posterType.width && currentHeight === posterType.height)) {
      for (const pageKey of Object.keys(updatedPoster.pages.pagesHashMap)) {
        const page = updatedPoster.pages.pagesHashMap[pageKey];
        this.resizePosterToTargetType(updatedPoster, pageKey, posterType);

        if (page.background.details.type === BackgroundTypeName.IMAGE) {
          const imageBackground = page.background.details;
          const bgDimsAndCoordsData = getBgDimsAndCoordsData(updatedPoster, posterType, imageBackground);

          if (!imageBackground.imageBackgroundItemObject.cropData) {
            imageBackground.imageBackgroundItemObject.cropData = {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              imageWidth: 0,
              imageHeight: 0,
              cropped: true,
            };
          }
          imageBackground.imageBackgroundItemObject.cropData.x = bgDimsAndCoordsData.x;
          imageBackground.imageBackgroundItemObject.cropData.y = bgDimsAndCoordsData.y;
          imageBackground.imageBackgroundItemObject.cropData.width = bgDimsAndCoordsData.width;
          imageBackground.imageBackgroundItemObject.cropData.height = bgDimsAndCoordsData.height;
        }
      }

      updatedPoster.width = posterType.width;
      updatedPoster.height = posterType.height;
    }
    updatedPoster.type = posterType;
    return updatedPoster;
  }
}

export const getResizeDataFromPoster = (poster: PosterLoadObject): ResizeReferenceObject => {
  const graphicItemsMap: Record<string, ItemObject> = {};
  const backgroundMap: Record<string, ImageBackgroundItemObject> = {};
  const pagesHashmap = poster.pages.pagesHashMap;

  for (const pageKey of Object.keys(pagesHashmap)) {
    const items = pagesHashmap[pageKey].items.itemsHashMap;
    for (const itemKey of Object.keys(items)) {
      const item = items[itemKey];
      graphicItemsMap[item.uid] = item;
    }

    if (pagesHashmap[pageKey].background.details.type === BackgroundTypeName.IMAGE) {
      const imageBackground = pagesHashmap[pageKey].background.details as ImageBackgroundObject;
      backgroundMap[imageBackground.imageBackgroundItemObject.uid] = imageBackground.imageBackgroundItemObject;
    }
  }

  return {
    width: poster.width,
    height: poster.height,
    graphicItems: graphicItemsMap,
    background: backgroundMap,
  };
};

const getParametersForResizing = (targetWidth: number, targetHeight: number, currentAspectRatio: number): DimesionsAndCoordinates => {
  let newHeight;
  let newWidth;

  newHeight = targetHeight;
  newWidth = targetHeight * currentAspectRatio;

  if (newWidth > targetWidth) {
    newWidth = targetWidth;
    newHeight = targetWidth / currentAspectRatio;
  }

  return {height: newHeight, width: newWidth, x: (targetWidth - newWidth) / 2, y: (targetHeight - newHeight) / 2};
};

const coordinatesAndDimensionsForBackground = (background: ImageBackgroundObject, targetAspectRatio: number): DimesionsAndCoordinates => {
  const backgroundRatio = background.imageBackgroundItemObject.cropData.width / background.imageBackgroundItemObject.cropData.height;
  const bgData = {} as DimesionsAndCoordinates;
  if (backgroundRatio < targetAspectRatio) {
    bgData.x = background.imageBackgroundItemObject.cropData.x;
    bgData.width = background.imageBackgroundItemObject.cropData.width;
    bgData.height = Math.floor(bgData.width / targetAspectRatio);
    bgData.y = background.imageBackgroundItemObject.cropData.y + (background.imageBackgroundItemObject.cropData.height - bgData.height) / 2;
  } else {
    bgData.y = background.imageBackgroundItemObject.cropData.y;
    bgData.height = background.imageBackgroundItemObject.cropData.height;
    bgData.width = Math.floor(bgData.height * targetAspectRatio);
    bgData.x = background.imageBackgroundItemObject.cropData.x + (background.imageBackgroundItemObject.cropData.width - bgData.width) / 2;
  }
  return bgData;
};

const getBgDimsAndCoordsData = (poster: PosterObject, posterType: PosterTypeObject, imageBackground: ImageBackgroundObject): DimesionsAndCoordinates => {
  const currentAspectRatio = poster.width / poster.height;
  const targetAspectRatio = posterType.width / posterType.height;

  let bgDimsAndCoordsData;
  if (currentAspectRatio < targetAspectRatio || (posterType.width === posterType.height && poster.width < poster.height)) {
    if (imageBackground.imageBackgroundItemObject?.cropData) {
      bgDimsAndCoordsData = coordinatesAndDimensionsForBackground(imageBackground, targetAspectRatio);
    } else {
      bgDimsAndCoordsData = {
        width: imageBackground.imageBackgroundItemObject.width,
        height: Math.floor(imageBackground.imageBackgroundItemObject.width / targetAspectRatio),
        x: 0,
        y: (imageBackground.imageBackgroundItemObject.height - Math.floor(imageBackground.imageBackgroundItemObject.width / targetAspectRatio)) / 2,
      };
    }
  } else if (imageBackground.imageBackgroundItemObject.cropData) {
    bgDimsAndCoordsData = coordinatesAndDimensionsForBackground(imageBackground, targetAspectRatio);
  } else {
    bgDimsAndCoordsData = {
      width: Math.floor(imageBackground.imageBackgroundItemObject.height * targetAspectRatio),
      height: imageBackground.imageBackgroundItemObject.height,
      y: 0,
      x: (imageBackground.imageBackgroundItemObject.width - Math.floor(imageBackground.imageBackgroundItemObject.height * targetAspectRatio)) / 2,
    };
  }
  return bgDimsAndCoordsData;
};
